"use client";

import React, { useEffect } from "react";

import { useImpression } from "@/hooks/useImpression";

const SEND_INTERVAL = 0.3 * 60 * 1000; // 30 seconds

const ImpressionManager = () => {
  const { sendImpressionsToBackend } = useImpression();

  useEffect(() => {
    // Handle visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // console.debug("Page is hidden");
        sendImpressionsToBackend();
      }
    };

    // Handle beforeunload
    const handleBeforeUnload = () => {
      sendImpressionsToBackend();
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Send impressions to backend every SEND_INTERVAL
    const interval = setInterval(sendImpressionsToBackend, SEND_INTERVAL);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearInterval(interval);
    };
  }, [sendImpressionsToBackend]);

  return null;
};

export default ImpressionManager;
