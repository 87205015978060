"use client";

import { useEffect } from "react";

import useAnalytics from "@/hooks/useAnalytics";
import { getOrCreateVisitorId } from "@/lib/identity";

const CSInitHandler = () => {
  const { identify } = useAnalytics();

  useEffect(() => {
    if (identify) {
      const visitorId = getOrCreateVisitorId();
      identify(visitorId, { visitor_id: visitorId });
    }
  }, [identify]);

  return null;
};

export default CSInitHandler;
