"use client";

// import { ThemeProvider as NextThemesProvider } from 'next-themes'
// import { type ThemeProviderProps } from 'next-themes/dist/types'
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

// export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
//   return <ThemeProvider {...props}>{children}</ThemeProvider>
// }

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? "", {
    api_host: "https://us.i.posthog.com",
  });
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
