import { isEmpty } from "radash";
import React, { useCallback, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";

const SEARCH_HISTORY_KEY = "searchHistories";
const COLLAPSED_HISTORY_COUNT = 4;

interface SearchHistoryProps {
  searchHistories: string[];
  setSearchHistories: React.Dispatch<React.SetStateAction<string[]>>;
  onSearch: (term: string) => void;
}

const SearchHistory: React.FC<SearchHistoryProps> = ({
  searchHistories,
  setSearchHistories,
  onSearch,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const removeSearchHistory = useCallback(
    (query: string) => {
      const updatedHistories = searchHistories.filter((h) => h !== query);
      setSearchHistories(updatedHistories);
      localStorage.setItem(
        SEARCH_HISTORY_KEY,
        JSON.stringify(updatedHistories),
      );
    },
    [searchHistories, setSearchHistories],
  );

  const clearSearchHistories = useCallback(() => {
    setSearchHistories([]);
    localStorage.setItem(SEARCH_HISTORY_KEY, "[]");
  }, [setSearchHistories]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayedHistories = isExpanded
    ? searchHistories
    : searchHistories.slice(0, COLLAPSED_HISTORY_COUNT);

  if (isEmpty(searchHistories)) {
    return null;
  }

  return (
    <>
      <h4 className="mb-2 text-base font-semibold">Search History</h4>
      {displayedHistories.map((history, index) => (
        <div
          key={index}
          className="flex items-center justify-between gap-4 rounded-lg p-2 hover:bg-gray-50"
        >
          <span
            onClick={() => onSearch(history)}
            className="flex-1 cursor-pointer"
          >
            {history}
          </span>
          <div
            className="cursor-pointer p-1"
            onClick={() => removeSearchHistory(history)}
          >
            <RiDeleteBinLine className="text-gray-500" />
          </div>
        </div>
      ))}

      {searchHistories.length > COLLAPSED_HISTORY_COUNT && !isExpanded && (
        <button
          onClick={toggleExpand}
          className="mb-4 mt-2 w-full text-center text-gray-600"
        >
          See more
        </button>
      )}

      {isExpanded && (
        <button
          onClick={clearSearchHistories}
          className="mb-4 mt-4 w-full text-center text-gray-600"
        >
          Clear All History
        </button>
      )}
    </>
  );
};

export default SearchHistory;
