"use client";

import { Search } from "lucide-react";
import { useRouter } from "next/navigation";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import SearchForm from "./SearchForm";
import SearchHistory from "./SearchHistory";
import SearchSuggestions from "./SearchSuggestions";

const SEARCH_HISTORY_KEY = "searchHistories";
const MAX_SEARCH_HISTORY = 10;

const HeaderSearch: React.FC = () => {
  const router = useRouter();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchHistories, setSearchHistories] = useState<string[]>([]);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Load search history
    const loadSearchHistory = async () => {
      const historiesString = localStorage.getItem(SEARCH_HISTORY_KEY);
      setSearchHistories(JSON.parse(historiesString || "[]"));
    };
    loadSearchHistory();

    // Clean up function
    return () => {
      document.body.style.overflow = "";
    };
  }, [isExpanded]);

  const toggleSearch = useCallback(() => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setSearchTerm("");
    }
  }, [isExpanded]);

  const saveSearchHistory = useCallback(async (query: string) => {
    const oldHistories: string[] = JSON.parse(
      localStorage.getItem(SEARCH_HISTORY_KEY) || "[]",
    );
    const updatedHistories = [
      query,
      ...oldHistories.filter((h) => h !== query),
    ].slice(0, MAX_SEARCH_HISTORY);
    setSearchHistories(updatedHistories);
    localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(updatedHistories));
  }, []);

  const search = useCallback(
    (query: string) => {
      const trimmedQuery = query.trim();
      if (trimmedQuery === "") return;

      setSearchTerm(trimmedQuery);
      saveSearchHistory(trimmedQuery);
      router.push(`/search?q=${encodeURIComponent(trimmedQuery)}`);
      toggleSearch();
    },
    [saveSearchHistory, router, toggleSearch],
  );

  // Render the search modal with a portal
  const renderSearchModal = () => {
    if (!isExpanded) return null;

    return createPortal(
      <div
        className="fixed inset-0 z-[10000] flex bg-white md:items-center md:justify-center md:bg-black/30 md:backdrop-blur-sm"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            toggleSearch();
          }
        }}
      >
        <div className="flex h-full w-full flex-col overflow-hidden md:h-auto md:max-h-[80vh] md:w-[600px] md:rounded-3xl md:bg-white md:shadow-xl">
          <SearchForm
            inputRef={inputRef}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onSearch={search}
            onClickBack={toggleSearch}
          />

          <div className="flex-1 overflow-y-auto p-4">
            <SearchHistory
              searchHistories={searchHistories}
              setSearchHistories={setSearchHistories}
              onSearch={search}
            />
            <div className="mt-4">
              <SearchSuggestions onSearch={search} />
            </div>
          </div>
        </div>
      </div>,
      document.body,
    );
  };

  return (
    <div className="relative flex items-center self-stretch text-sm">
      {/* Mobile Search Button */}
      <button
        onClick={toggleSearch}
        className="flex items-center justify-center rounded-xl border border-gray-200 bg-gray-50 px-4 py-2 text-gray-600 hover:border-gray-300 hover:text-gray-900 hover:text-white focus:outline-none md:hidden"
      >
        <Search size={16} className="mr-2 text-gray-500" />
        <span className="text-gray-500">Search</span>
      </button>

      {/* Desktop Search Bar */}
      <button
        onClick={toggleSearch}
        className="hidden w-[500px] items-center justify-center rounded-xl border border-gray-200 bg-gray-50 px-4 py-2.5 transition-colors hover:border-gray-300 hover:bg-white hover:text-gray-900 focus:outline-none md:flex"
      >
        <Search size={16} className="mr-2 text-gray-500" />
        <span className="text-gray-500">Search anything</span>
      </button>

      {isMounted && renderSearchModal()}
    </div>
  );
};

export default HeaderSearch;
