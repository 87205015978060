"use client";

import { Menu, Search, X } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import { RiFeedbackLine } from "react-icons/ri";

import useAnalytics from "@/hooks/useAnalytics";

import HeaderSearch from "./search/HeaderSearch";

export function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { capture } = useAnalytics();

  return (
    <>
      {/* Fixed wrapper for the nav */}
      <div className="fixed left-0 right-0 top-0 z-40 border-b border-gray-200/50 bg-white/90 backdrop-blur-md supports-[backdrop-filter]:bg-white/85">
        <nav className="mx-auto flex max-w-7xl items-center justify-between md:grid md:grid-cols-[1fr,auto,1fr] md:gap-4">
          <Link
            href="/"
            className="mobile-m:pr-4 flex items-center gap-2 py-2 pl-3 md:pl-6"
          >
            <Image
              src="/images/app_icon.png"
              alt="App Icon"
              width={40}
              height={40}
              className="rounded-lg"
            />
            <div className="flex flex-col">
              <h1 className="text-lg font-bold leading-5">brek</h1>
              <span className="mobile-m:text-sm line-clamp-1 text-xs font-medium text-black">
                {`Mom life guide`}
              </span>
            </div>
          </Link>

          <div className="hidden md:block">
            <HeaderSearch />
          </div>

          <div className="flex items-center md:justify-end">
            <div className="md:hidden">
              <HeaderSearch />
            </div>
            <button
              onClick={() => setIsMenuOpen(true)}
              className="px-4 py-2 text-gray-600 hover:text-gray-900 focus:outline-none md:hidden"
            >
              <Menu size={24} />
            </button>
          </div>
        </nav>
      </div>

      {/* Spacer to prevent content from going under the fixed nav */}
      <div className="h-[72px]" />

      {/* Mobile Menu Modal */}
      {isMenuOpen && (
        <div className="fixed inset-0 z-50 flex flex-col bg-gray-100">
          {/* Header with close button */}
          <div className="flex justify-end bg-white p-2">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none"
            >
              <X size={24} />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto px-4 pb-8 pt-4">
            {/* App Download Section */}
            <div className="mb-6 flex flex-col items-center rounded-2xl bg-white p-6">
              <Image
                src="/images/app_icon.png"
                alt="App Icon"
                width={60}
                height={60}
                className="rounded-xl shadow-sm"
              />
              <h2 className="mb-0 mt-4 text-lg font-bold">
                brek - Mom life guide
              </h2>
              <p className="mb-6 text-center text-sm text-gray-500">
                Join thousands of moms to share experiences & tips
              </p>
              <div className="flex gap-2">
                <Link
                  href="https://apps.apple.com/app/id6504866617"
                  onClick={() => {
                    capture("app_store_button_click", {
                      platform: "ios",
                      location: "menu",
                    });
                  }}
                >
                  <Image
                    src="/images/app_store.svg"
                    alt="Download on App Store"
                    width={120}
                    height={40}
                    className="cursor-pointer transition-opacity hover:opacity-80"
                  />
                </Link>
                <Link
                  href="https://play.google.com/store/apps/details?id=com.pageonelab.brekapp"
                  onClick={() => {
                    capture("app_store_button_click", {
                      platform: "android",
                      location: "menu",
                    });
                  }}
                >
                  <Image
                    src="/images/google_play.svg"
                    alt="Get it on Google Play"
                    width={120}
                    height={40}
                    className="cursor-pointer transition-opacity hover:opacity-80"
                  />
                </Link>
              </div>
            </div>

            {/* Community Links Group */}
            <div className="mb-6 overflow-hidden rounded-2xl bg-white">
              <Link
                href="/terms/community-guideline"
                className="flex items-center border-b border-gray-100 px-6 py-4 text-gray-900"
                onClick={() => setIsMenuOpen(false)}
              >
                Community Guidelines
              </Link>
              <Link
                href="/feedback"
                className="flex items-center px-6 py-4 text-gray-900"
                onClick={() => setIsMenuOpen(false)}
              >
                Feedbacks
              </Link>
            </div>

            {/* Legal Links Group */}
            <div className="overflow-hidden rounded-2xl bg-white">
              <Link
                href="/terms/privacy-policy"
                className="flex items-center border-b border-gray-100 px-6 py-4 text-gray-900"
                onClick={() => setIsMenuOpen(false)}
              >
                Privacy
              </Link>
              <Link
                href="/terms/terms-of-service"
                className="flex items-center border-b border-gray-100 px-6 py-4 text-gray-900"
                onClick={() => setIsMenuOpen(false)}
              >
                Terms
              </Link>
              <Link
                href="/terms/cookies-policy"
                className="flex items-center px-6 py-4 text-gray-900"
                onClick={() => setIsMenuOpen(false)}
              >
                Cookie Policies
              </Link>
            </div>

            <div className="mt-4 flex items-center justify-center text-sm text-gray-400">
              &copy; {new Date().getFullYear()} Brek
            </div>
          </div>
        </div>
      )}
    </>
  );
}
