import React from "react";
import { RiArrowLeftSLine } from "react-icons/ri";

interface SearchFormProps {
  inputRef: React.RefObject<HTMLInputElement>;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  onSearch: (term: string) => void;
  onClickBack: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({
  inputRef,
  searchTerm,
  setSearchTerm,
  onSearch,
  onClickBack,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex h-14 items-center border-b p-4"
    >
      <RiArrowLeftSLine
        className="mr-2 size-8 cursor-pointer text-black"
        onClick={onClickBack}
      />
      <input
        ref={inputRef}
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        placeholder="Search..."
        className="w-full text-base focus:outline-none"
      />
      <button type="submit" className="ml-2 font-medium">
        Search
      </button>
    </form>
  );
};

export default SearchForm;
