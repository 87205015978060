import React from "react";

import { useSearchSuggestions } from "@/api/useSearchApi";

interface SearchSuggestionsProps {
  onSearch: (term: string) => void;
}

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({ onSearch }) => {
  const { data: searchSuggestions } = useSearchSuggestions();

  return (
    <>
      <h4 className="mb-2 text-base font-semibold">You may be interested in</h4>
      {searchSuggestions?.map((suggestion, index) => (
        <div
          key={index}
          onClick={() => onSearch(suggestion)}
          className="cursor-pointer p-2 hover:bg-gray-100"
        >
          {suggestion}
        </div>
      ))}
    </>
  );
};

export default SearchSuggestions;
